import { useState } from "react"
import styles from "./CreatePostForm.module.scss"
import { useSelector } from "react-redux"

export default function CreatePostForm() {
    const [fsormIsopen, setFormIsopen] = useState<Boolean>(true)
    const formIsOpen = useSelector(store => store)
console.log(formIsOpen)

    const handleFormClose= (e:any) =>{
        setFormIsopen(prev => !prev)
   
    }

    // onClick={handleForm}}
    return (
        <div className={styles.backgroundContainer} onClick={(e) => handleFormClose(e)}  style={{ width: formIsOpen ? "100%" : "0px" }}>
            <form className={styles.container} onClick={(e) => e.stopPropagation()}>
            </form>
        </div>
    )
}

