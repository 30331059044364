import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './Globals.scss';
import Contact from './Contact';
import { LandingPage, LoginPage, RegisterPage, FeedPage } from 'pages';

interface Props { }

const App: React.FC<Props> = () => {
	return (
		<Routes>
			<Route path='/' element={<LandingPage />} />
			<Route path='/contact' element={<Contact />} />
			<Route path='/login' element={<LoginPage />} />
			<Route path='/register' element={<RegisterPage />} />
			<Route path="/feed" element={<FeedPage />} />
		</Routes>
	);
};

export default App;
