import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import { ContactSchema } from 'schemas/Contact.schema';

export default function Contact() {
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			message: '',
			number: ''
		},
		validationSchema: ContactSchema,
		onSubmit: (values) => {
			console.log(values);
			formik.resetForm();
			emailjs.send('service_hys7roy', 'template_7tpki0s', { ...values }, 'mhF51X1JIIywVN2K4').then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		}
	});
	return (
		<div className='relative bg-gray-50 h-screen pt-20'>
			<div className='absolute inset-0'>
				<div className='absolute inset-y-0 left-0 w-1/2 bg-gray-50' />
			</div>
			<div className='w-full mx-auto text-center pb-10'>
				<h1 className='relative text-6xl text-black'>7ady3rf</h1>
			</div>
			<div className='relative mx-auto w-full lg:grid lg:grid-cols-5'>
				<div className=' py-16 px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
					<div className='mx-auto max-w-lg'>
						<h2 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>Get in touch</h2>
						<p className='mt-3 text-lg leading-6 text-gray-500'>We’d love to hear from you! Send us a message using the form opposite, or email us.</p>
						<dl className='mt-8 text-base text-gray-500'>
							<div>
								<dt className='sr-only'>Postal address</dt>
								<dd>
									<p>Cairo, Egypt</p>
									<p>Ismaillia City</p>
								</dd>
							</div>
							<div className='mt-6'>
								<dt className='sr-only'>Phone number</dt>
								<dd className='flex'>
									<PhoneIcon className='h-6 w-6 flex-shrink-0 text-gray-400' aria-hidden='true' />
									<span className='ml-3'>+201282211753</span>
								</dd>
							</div>
							<div className='mt-3'>
								<dt className='sr-only'>Email</dt>
								<dd className='flex'>
									<EnvelopeIcon className='h-6 w-6 flex-shrink-0 text-gray-400' aria-hidden='true' />
									<span className='ml-3'>support@7ady3rf.com</span>
								</dd>
							</div>
						</dl>
						<p className='mt-6 text-base text-gray-500'>
							Looking for Polices?{' '}
							<a href='https://www.privacypolicies.com/live/eab166f3-6ae2-45a0-bfca-e5511122a062' target='_blank' rel='noreferrer' className='font-medium text-gray-700 underline'>
								Go To Homepage
							</a>
							.
						</p>
					</div>
				</div>
				<div className='bg-white py-16 px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12'>
					<div className='mx-auto max-w-lg lg:max-w-none'>
						<form onSubmit={formik.handleSubmit} className='grid grid-cols-1 gap-y-6'>
							<div>
								<label htmlFor='full-name' className='sr-only'>
									Full name
								</label>
								<input type='text' name='name' id='full-name' value={formik.values.name} onBlur={formik.handleBlur} onChange={formik.handleChange} autoComplete='name' className='block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500' placeholder='Full name' />
							</div>
							<div>
								<label htmlFor='email' className='sr-only'>
									Email
								</label>
								<input id='email' name='email' type='email' value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} autoComplete='email' className='block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500' placeholder='Email' />
							</div>
							<div>
								<label htmlFor='phone' className='sr-only'>
									Phone
								</label>
								<input type='text' name='number' id='phone' value={formik.values.number} onBlur={formik.handleBlur} onChange={formik.handleChange} autoComplete='tel' className='block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500' placeholder='Phone' />
							</div>
							<div>
								<label htmlFor='message' className='sr-only'>
									Message
								</label>
								<textarea id='message' name='message' value={formik.values.message} onBlur={formik.handleBlur} onChange={formik.handleChange} rows={4} className='block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500' placeholder='Message' defaultValue={''} />
							</div>
							<div>
								<button type='submit' className='inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
									Submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
