import Login from 'components/authForms/LoginForm';
import styles from './login.module.scss';

export default function LoginPage() {
	return (
		<div className={styles['LoginPageContainer']}>
			<Login />
		</div>
	);
}
