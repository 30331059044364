import RegisterForm from 'components/authForms/RegisterForm';
import styles from './register.module.scss';

export default function RegisterPage() {
	return (
		<div className={styles.pageContainer}>
			<RegisterForm />
		</div>
	);
}
