import CreatePostForm from "components/createPostForm/CreatePostForm"
import styles from "./feed.module.scss"
import { FeedRow } from "components"

export default function FeedPage() {


    return(
        <div className={styles.feedContainer}>
               <FeedRow />
               <CreatePostForm />
        </div>
    )
}