import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import './Globals.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(

	<React.StrictMode>
		  <Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
		</Provider>
	</React.StrictMode>
);
