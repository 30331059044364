import ImageContainer from 'components/subCompnents/imageContainer/ImageContainer';
import styles from './FeedCard.module.scss';
import { useState } from 'react';
import { likeFilled,likeHollow,commentIcon, menuIcon } from 'assets';
export default function FeedCard() {
    const [subMenu, setSubMenu] = useState(false)

    const handleSubMenu = () => {
        setSubMenu(prev => !prev)
    }

    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <div className={styles.topLeft}>
                    <ImageContainer url={''} />
                    <div className={styles.nameAndDate}>
                        <h4>Ammar omar</h4>
                        <span>yesterday</span>
                    </div>
                </div>
                <div className={styles.topRight} onClick={handleSubMenu}>
                    <img src={menuIcon} />
                </div>
                <div className={styles.subMenu} style={{ display: subMenu ? "block" : "none" }}>
                    <div className={styles.subMenuItem}>
                        icon
                        <p>Save</p>
                    </div>
                    <div className={styles.subMenuItem}>
                        icon
                        <p>Hide</p>
                    </div>
                    <div className={styles.subMenuItem}>
                        icon
                        <p>Block user</p>
                    </div>
                </div>
            </div>
            <div className={styles.postContent}>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus necessitatibus reiciendis, corrupti dolorem, inventore ipsum iusto fugiat doloribus sit maxime, itaque libero laborum quaerat? Accusantium eligendi cupiditate ea dolorem qui.
                    Accusantium perferendis facilis quibusdam amet voluptatem asperiores. Iure error porro veniam, nihil atque quidem pariatur laudantium exercitationem, enim amet aliquid vitae id corporis obcaecati ut accusantium est provident? Repudiandae, expedita?</p>
            </div>
            <div className={styles.bottomSection}>
                <div className={styles.bottomButton}>
                    <div className={styles.iconContainer}>
                        <img src={likeHollow} />
                    </div>
                    <button>
                        Like
                    </button>
                </div>
                <div className={styles.bottomButton}>
                    <div className={styles.iconContainer}>
                        <img src={commentIcon} />
                    </div>
                    <button>
                        comment
                    </button>
                </div>


            </div>
        </div>
    )
}