import ImageContainer from "components/subCompnents/imageContainer/ImageContainer"
import styles from "./AddPost.module.scss"


export default function AddPost(){
const openCreatePostForm = () =>{

}

    return(
       <div className={styles.container}>
       <ImageContainer url={""} />
            <div className={styles.createPostContainer} onClick={openCreatePostForm}>
                <span>Do you have a question in mind?</span>
            </div>
       </div>
    )
}