import { Link } from 'react-router-dom';
import styles from './Hero.module.scss';
import Phone from '../phone/Phone';

export default function HeroSection() {
	return (
		<div className={styles.homeContainerParent}>
			<div className={styles.movingObj}>?</div>
			<div className={styles.heroContainer}>
				<div className={styles.center}>
					<h1 className='uppercase'>Do you have a question ?</h1>
					<h2>Millions are willing to answer</h2>
					<div className={styles.links}>
						<Link to='/login' className='button-primary'>
							Get Started
						</Link>
						<Link to='/' className='button-weak'>
							Learn More ...
						</Link>
					</div>
				</div>
				<div className={styles.phoneContainer}>
					<Phone />
				</div>
			</div>
		</div>
	);
}
