import { createSlice } from '@reduxjs/toolkit'

// Slice

const createPostForm = createSlice({
    name: 'createPostForm',
    initialState: false,
    reducers: {
        formIsOpen: (state, action) => {
           state = action.payload
        },
    },
});
export const {formIsOpen} = createPostForm.actions
export default createPostForm.reducer