import styles from './Phone.module.scss';
import phone from 'assets/images/phone.png';
import phoneBg from 'assets/images/BGImagePhone.jpg';

export default function Phone2() {
	return (
		<div className={styles.phoneContainer}>
			<div className={styles.bgImagePhone}>
				<img src={phoneBg}></img>
			</div>
			<img src={phone} className={styles.phoneImage} />
		</div>
	);
}
