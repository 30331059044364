import styles from './FeedRow.module.scss';
import { FeedCard, AddPost } from "components"

export default function FeedRow() {
    return (
        <div className={styles.container}>
            <AddPost />
            <FeedCard />
            <FeedCard />
            <FeedCard />
            <FeedCard />

        </div>
    )
}